import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SRow, SCol, AuiUploader, SDialog } from "@avalara/skylab-react";
import { logger } from "../../../shared/logger";
import {
  selectSession,
  selectUploadKey,
  fetchUploadKeyAsync,
  postFileDetailsAsync,
  fetchLockerFilesAsync,
  selectIsUploadDialogOpen,
  setIsUploadDialogOpen,
  fetchLockerSizeAsync,
} from "./index";
import { useState } from "react";
import toast from "../../../hooks/toast";
import { getConfig } from "../../../services/configService"
import { getConfigurationId } from "../../../shared/utils";

const UploadDocuments = React.memo((props) => {
  const session = useSelector(selectSession, shallowEqual);
  const lockerDefinitionId = props.locker.lockerDefinitionId;
  const lockerInstanceId = props.locker.lockerInstanceIds[0];
  const dispatch = useDispatch();
  const uploadKey = useSelector(selectUploadKey, shallowEqual);
  let isDialogOpen = useSelector(
    selectIsUploadDialogOpen(lockerDefinitionId, shallowEqual)
  );
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [isFileShare, setIsFileShare] = useState(false);
  const showFileShare = props.locker.securityMode === 3 ? true : false;
  const [startValidation, setStartValidation] = useState(false);
  const [fileDetails, setFileDetails] = useState(null);
  const [isEnable, setIsEnable] = useState(false);
  const auiUploaderRef = React.createRef();
  const [showToast] = toast();

  useEffect(() => {
    if (session.activeCompany.id !== null) dispatch(fetchUploadKeyAsync());
  }, []); // eslint-disable-line

  async function handleErrorEvent(e) {
    const params = {
      message: e.detail.error,
      filename: "archivedTransactionReports/Uploader",
    };
    await logger(2, 4, params);
  }

  async function handleAttachEvent(e) {
    const fileDetail = {
      name: e.detail.files[0].name,
      size: e.detail.files[0].size,
      type: e.detail.files[0].type,
      title: title,
      category: category,
      description: description,
      isFileShare: isFileShare,
    };
    if (fileDetail?.size === 0) {
      onSDismiss();
      showToast("warning", "Empty file can not be uploaded.");
      return;
    }

    setFileDetails(fileDetail);
    setIsEnable(true);
  }

  async function handleRemove(e) {
    setFileDetails(null);
    clearFields();
    setIsEnable(false);
  }

  async function handleValidateEvent(e) {
    const { filesPassedValidation, filesFailedValidation } = e.detail;
    let message = "";
    let logLevel = 1;
    if (filesPassedValidation.length > 0) {
      message = `filesPassedValidation :filename:${filesPassedValidation[0].name} ,bucketKey :${filesPassedValidation[0].key}`;
    }
    if (filesFailedValidation.length > 0) {
      message = `filesFailedValidation :filename:${filesFailedValidation[0].name}, message: ${filesFailedValidation[0].message}`;
      logLevel = 2;
    }

    const params = {
      message: message,
      filename: "archivedTransactionReports/Uploader",
    };
    await logger(2, logLevel, params);
    setTimeout(() => {
      dispatch(fetchLockerFilesAsync(lockerDefinitionId, true));
      dispatch(fetchLockerSizeAsync(lockerDefinitionId));
    }, 3000);
  }
  const clearFields = () => {
    setTitle("");
    setCategory("");
    setDescription("");
    setCategoryError("");
    setTitleError("");
    setDescriptionError("");
    setIsEnable(false);
    if (auiUploaderRef.current) auiUploaderRef.current.reset();
  };

  const onSDismiss = (e) => {
    clearFields();
    dispatch(setIsUploadDialogOpen({ id: lockerDefinitionId, value: false }));
  };

  async function saveFile() {
    await dispatch(postFileDetailsAsync(fileDetails, lockerDefinitionId));
    await dispatch(fetchLockerFilesAsync(lockerDefinitionId, true));
    dispatch(setIsUploadDialogOpen({ id: lockerDefinitionId, value: false }));
  }

  const onSave = () => {
    if (titleError || categoryError || descriptionError) {
      return;
    }
    if (fileDetails) {
      setStartValidation(true);
      saveFile();
    }
  };

  const handleChangeInput = (setter) => {
    return (event) => {
      switch (event.target.name) {
        case "title":
          if (event.target.value.length <= 50) {
            setTitleError("");
          } else {
            setTitleError("error");
            return;
          }
          break;
        case "category":
          if (event.target.value.length <= 50) {
            setCategoryError("");
          } else {
            setCategoryError("error");
            return;
          }
          break;
        case "description":
          if (event.target.value.length <= 250) {
            setDescriptionError("");
          } else {
            setDescriptionError("error");
            return;
          }
          break;
        default:
      }

      setter(
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value
      );
    };
  };

  const uploadDocDom = (
    <>
      <SRow className="margin-bottom-none  padding-bottom-none">
        <SCol span="4">
          <label htmlFor="title">Title</label>
          <input
            id="title"
            onChange={handleChangeInput(setTitle)}
            type="text"
            name="title"
            value={title}
            className={titleError}
            rows="1"
          ></input>
          {titleError ? (
            <div className="input-msg">
              <span className="top-xs">Exceeds the 50 characters limit.</span>
            </div>
          ) : null}
        </SCol>
        <SCol span="4">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            type="text"
            rows="1"
            value={description}
            className={descriptionError}
            onChange={handleChangeInput(setDescription)}
          ></textarea>
          {descriptionError ? (
            <div className="input-msg">
              <span className="top-xs">Exceeds the 250 characters limit.</span>
            </div>
          ) : null}
        </SCol>
        <SCol span="4">
          <label htmlFor="category">Category</label>
          <input
            id="category"
            onChange={handleChangeInput(setCategory)}
            type="text"
            className={categoryError}
            value={category}
            name="category"
            rows="1"
          ></input>
          {categoryError ? (
            <div className="input-msg">
              <span className="top-xs">Exceeds the 50 characters limit.</span>
            </div>
          ) : null}
        </SCol>
      </SRow>
      <SRow>
        {showFileShare ? (
          <SCol span="6" className="padding-bottom-none">
            <input
              type="checkbox"
              id="fileshare"
              value="fileshare"
              onChange={handleChangeInput(setIsFileShare)}
            />
            <label htmlFor="fileshare">Share the file with all users?</label>
          </SCol>
        ) : null}
      </SRow>
      <SRow>
        <SCol span="12" id="divfileUploader" className="pad-bottom-none">
          <AuiUploader
            id="aui-uploader"
            ref={auiUploaderRef}
            multiple={false}
            stagingInlineCta={false}
            ctaText="Upload files"
            stagingExternalCta={true}
            configurationId={getConfigurationId(getConfig().REACT_APP_ENV)}
            callbackurl={`https://${getConfig().REACT_APP_API_HOST}/companies/${
              session.activeCompany.id
            }/lockerinstances/${lockerInstanceId}/addfile?key=${encodeURIComponent(
              uploadKey
            )}`}
            requestCurrentAccessToken={() => {
              return Promise.resolve({ accessToken: session.accessToken });
            }}
            onS-attach={handleAttachEvent}
            onS-remove={handleRemove}
            onS-validate={handleValidateEvent}
            onS-error={handleErrorEvent}
            onS-validationstart={startValidation}
          >
            <div slot="guidelines">
              <div>
                File must be
                {props.locker.allowedFileExtension === "*.*"
                  ? `*.PDF,*.CSV,*.XLS,*.XLSX,*.TXT or ZIP `
                  : ` ${props.locker.allowedFileExtension} `}
                and under 200MB
              </div>
            </div>
            <div slot="disabled-content">
              <div>
                Not able to upload this file due to its exceeded locker max-size
              </div>
            </div>
            <div
              slot="loading"
              className="flex flex-dir-col align-items-center pad-top-xl pad-bottom-xl text-center"
            >
              <s-loader loading />
              <h3>Files are being uploaded</h3>
            </div>
          </AuiUploader>
        </SCol>
      </SRow>
    </>
  );

  return (
    <>
      <SDialog
        open={isDialogOpen}
        id="upload-dialog"
        onS-dismiss={(e) => onSDismiss(e)}
        className="dl-upload-dialog-height"
        aria-modal="true"
      >
        <div slot="header" id="dialog-title">
          Add file
        </div>
        <div slot="body">{uploadDocDom}</div>
        <div slot="footer">
          <button className="secondary " onClick={(e) => onSDismiss(e)}>
            Cancel
          </button>
          <button className="primary " onClick={onSave} disabled={!isEnable}>
            Save
          </button>
        </div>
      </SDialog>
    </>
  );
});
export default UploadDocuments;
