import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { getConfig } from "../services/configService";

export const uploadSlice = createSlice({
  name: "upload",
  initialState: {
    uploadKey: null,
    lockerSize: 0,
    iswarning: false,
  },

  reducers: {
    setUploadKey: (state, action) => {
      state.uploadKey = action.payload;
    },
    setlockerSize: (state, action) => {
      state.lockerSize = action.payload;
    },

    setIsWarning: (state, action) => {
      state.iswarning = action.payload;
    },
  },
});

export const {
  setUploadKey,
  setlockerSize,
  setIsWarning,
} = uploadSlice.actions;

export const selectUploadKey = (state) => {
  return state.upload.uploadKey;
};

export const selectlockerSize = (state) => {
  return state.upload.lockerSize;
};

export const selectIsWarning = (state) => {
  return state.upload.iswarning;
};

export const fetchUploadKeyAsync = () => async (dispatch, getState) => {
  const session = getState().session;
  const response = await axios.get(
    `//${getConfig().REACT_APP_API_HOST}/companies/${session.activeCompany.id}/upload`,
    { withCredentials: true }
  );
  if (response.data) {
    dispatch(setUploadKey(response.data));
  }
};

export const postFileDetailsAsync = (e, lockerDefinitionId) => async (
  dispatch,
  getState
) => {
  const session = getState().session;
  await axios.post(
    `//${getConfig().REACT_APP_API_HOST}/companies/${session.activeCompany.id}/upload/file`,
    {
      fileName: e.name,
      fileSize: e.size,
      fileType: e.type,
      userId: session.userId,
      userName: session.username,
      companyId: session.activeCompany.id,
      lockerDefinitionId: lockerDefinitionId,
      title: e.title,
      category: e.category,
      description: e.description,
      isFileShare: e.isFileShare,
    },
    { withCredentials: true }
  );
};

export const fetchLockerSizeAsync = (lockerDefinitionId) => async (
  dispatch,
  getState
) => {
  const session = getState().session;
  const response = await axios.get(
    `//${getConfig().REACT_APP_API_HOST}/companies/${session.activeCompany.id}/lockerdefinitions/${lockerDefinitionId}/lockersize`,
    { withCredentials: true }
  );
  if (response.data) {
    dispatch(setlockerSize(response.data.size));
  }
};
export default uploadSlice.reducer;
