import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store/store";
import { loadConfig } from './services/configService';
import * as serviceWorker from "./serviceWorker";
 import { HelmetProvider } from "react-helmet-async";
import { ConfigProvider } from "./providers/ConfigProvider";
import Application from "./features/application/Application";

import "./base.css";

// Initialize the application
async function initApp() {
  try {
    // Load configuration before mounting the app
    await loadConfig();
     const container = document.getElementById("root");
     const root = createRoot(container);

    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <HelmetProvider>
            <ConfigProvider>
              <Application />
            </ConfigProvider>
          </HelmetProvider>
        </Provider>
      </React.StrictMode>
    );
  } catch (error) {
    console.error('Failed to initialize app:', error);
  }
}

// Start the application
initApp().catch(console.error);

serviceWorker.unregister();
