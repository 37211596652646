import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { SIcon, SRow, SCol } from "@avalara/skylab-react";

import { selectSession, setIsUploadDialogOpen, selectIsWarning } from "./index";
import { getConfig } from "../../../services/configService";

export default function LockerSubSection(props) {
  const dispatch = useDispatch();
  const session = useSelector(selectSession, shallowEqual);
  const accountId = session.accountId;
  const supportLink = `https://${getConfig().REACT_APP_SUPPORT_ACCOUNT}/#/a/${accountId}/support-cases/support-request?type=DREQ&companyId=${session.activeCompany.id}`;
  const lockerDefinitionId = props.locker.lockerDefinitionId;
  const enableUpload = props.locker.enableUpload;
  const isTechSupport = session.isInternalUser;
  const iswarning = useSelector(selectIsWarning);

  function openModel() {
    dispatch(setIsUploadDialogOpen({ id: lockerDefinitionId, value: true }));
  }

  const linkSections =
    props.locker.name === "Archived transaction reports" ? (
      <>
        <a
          target="_blank"
          rel="noreferrer"
          className="font-semibold margin-right-xs"
          href={supportLink}
        >
          <SIcon name="send" aria-hidden="true" className="small " />
          &nbsp; Request an archived transaction report
        </a>
      </>
    ) : null;

  const DOM = (
    <>
      <SRow>
        <SCol className="pad-bottom-none" span="auto">
          {(enableUpload || isTechSupport) && (
            <button
              onClick={openModel}
              className="pad-all-none  font-semibold text-center inline link margin-right-sm"
              disabled={iswarning}
            >
              <SIcon
                title="Add Files"
                name="plus"
                role="img"
                aria-hidden="true"
                className="small"
              />
              &nbsp;Add Files
            </button>
          )}
          {linkSections}
        </SCol>
      </SRow>
    </>
  );

  return <>{DOM}</>;
}
