
let configCache = null;

export async function loadConfig() {
    if (configCache) {
        return configCache;
    }

    // First try to get config from window.envConfig (set in index.html)
    if (window.envConfig) {
        configCache = window.envConfig;
        return configCache;
    }
        const response = await fetch('/envConfig.json');
        configCache = await response.json();
        return configCache;

}

export   function getConfig() {
    if (!configCache) {
        throw new Error('Configuration not loaded. Call loadConfig() first');
    }
    return configCache;
}


const configService = {
    loadConfig,
    getConfig,
};

export default configService;
