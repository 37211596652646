import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { BrowserRouter as Router, Outlet } from "react-router-dom";
import {
  AuiFooter,
  AuiIeBanner,
  AuiHeader,
  SLoader,
  SToasts,
  AuiSupernav,
} from "@avalara/skylab-react";

import {
  fetchSessionAsync,
  selectSession,
  selectSkylabHeader,
} from "../../store/sessionSlice";
import Navigation from "../navigation/Navigation";
import ErrorBoundary from "../../shared/Error/ErrorBoundary";
import { getConfig } from "../../services/configService";

function Application() {
  const dispatch = useDispatch();
  const session = useSelector(selectSession, shallowEqual);
  const header = useSelector(selectSkylabHeader, shallowEqual);
  const [hideNav, setHideNav] = useState(false);

  const accessTokenPromise = useCallback(() => {
    if (!session.accessToken) {
      return Promise.reject(new Error("Session access token not available"));
    }
    return Promise.resolve({ accessToken: session.accessToken });
  }, [session]);

  useEffect(() => {
    const ipuaPanel = document.querySelector("ipua-panel");
    if (ipuaPanel && session.accessToken !== null) {
      ipuaPanel.getaccesstoken = accessTokenPromise;
    }
    dispatch(fetchSessionAsync());
  }, [dispatch, session.accessToken, accessTokenPromise]);

  let appDOM = null;

  if (session.isLoading) {
    appDOM = (
      <div className="flex dl-flex-fill-height dl-flex-center">
        <h1>Loading Identity Session</h1>
        <SLoader id="page-loader" class="large" aria-live="polite" loading />
      </div>
    );
  } else {
    appDOM = (
      <div
        className={`flex dl-flex-fill-height ${
          !hideNav ? "margin-top-lg dl-content" : ""
        }`}
      >
        <Navigation setHideNav={setHideNav} />
        <Outlet />
      </div>
    );
  }
  return (
    <Router>
      <AuiIeBanner />
      <AuiHeader
        productName={header.productName}
        appKey={header.appKey}
        user={header.user}
        env={header.env}
        logoutUrl={header.logoutUrl}
        showChatButton
      />
      {session.accessToken !== null ? (
        <>
          <AuiSupernav
            requestCurrentAccessToken={accessTokenPromise}
            active="aui-supernav.document-central"
          />
        </>
      ) : null}

      {session.accessToken !== null ? (
        <>
          <ipua-panel
            product="Document Central"
            url={ getConfig().REACT_APP_IDENTITY_HOST}
          />
        </>
      ) : null}

      <ErrorBoundary>{appDOM}</ErrorBoundary>
      <SToasts role="status" />
      <AuiFooter user={header.user} />
    </Router>
  );
}
export default Application;
